import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { getAppUser, setAppUser, useAuth } from './contexts/AppContext';
import SharedContext from './contexts/SharedContext';
import FullLayout from './layout/FullLayout';
import LoginLayout from './layout/LoginLayout';
import { LoadingProgress } from './lib/components/LoadingProgress/LoadingProgress';
import LoginPage from './auth/pages/login/LoginPage';
import { getDivisionUserList, getUser } from './services/organizationService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useLang } from './lib/i18n';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { paths } from './utils/paths';


const localeMap: any = {
    en: enLocale,
    es: esLocale,
};

function App() {
    const logged = useAuth();
    const [userLoaded, setUserLoaded] = React.useState<boolean>(false);
    const lang = useLang();

    // On refresh page, we look for the user info
    useEffect(() => {
        if (getAppUser() == null && logged) {
            getUser().then(user => {
                getDivisionUserList().then(divisions => {
                    user.divisions = divisions;
                    setAppUser(user);
                    setUserLoaded(true);
                });
            });
        } else {
            setUserLoaded(true);
        }
    }, [logged]);

    const HomePage = lazy(() => import('./general/pages/HomePage'));
    const ChangePasswordPage = lazy(() => import('./changePassword/pages/ChangePasswordPage'))
    const HarvestListPage = lazy(() => import('./productiveUnit/pages/HarvestListPage'));
    const ProductiveUnitListPage = lazy(() => import('./productiveUnit/pages/ProductiveUnitListPage'));
    const ProductiveUnitAdminPage = lazy(() => import('./productiveUnit/pages/ProductiveUnitPage'));
    const ProductiveUnitNewPage = lazy(() => import('./productiveUnit/pages/ProductiveUnitNewPage'));
    const EventListPage = lazy(() => import('./event/pages/EventListPage'));
    const TechnicalMonitoring = lazy(() => import('./event/pages/EventTechnicalMonitoringPage'));
    const EventTechnicalMonitoringNewPage = lazy(() => import('./event/pages/EventTechnicalMonitoringNewPage'));
    const Event = lazy(() => import('./event/pages/EventPage'));
    const EventNewPage = lazy(() => import('./event/pages/EventNewPage'));
    const ReportListPage = lazy(() => import('./report/pages/ReportListPage'));
    const ReportPage = lazy(() => import('./report/pages/ReportPage'));

    const full =
        <Router>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[lang]}>
                <SharedContext>
                    <FullLayout sx={{p: 0}}>
                        <Routes>
                            <Route path={paths.index} element={<Suspense fallback={<LoadingProgress />}><HomePage /></Suspense>} />
                            <Route path={paths.home} element={<Suspense fallback={<LoadingProgress />}><HomePage /></Suspense>} />
                            <Route path={paths.changePassword} element={<Suspense fallback={<LoadingProgress/>}><ChangePasswordPage/></Suspense>} />
                            <Route path={paths.harvests} element={<Suspense fallback={<LoadingProgress />}><HarvestListPage /></Suspense>} />
                            <Route path={paths.productiveUnits} element={<Suspense fallback={<LoadingProgress />}><ProductiveUnitListPage /></Suspense>} />
                            <Route path={paths.productiveUnit} element={<Suspense fallback={<LoadingProgress />}><ProductiveUnitNewPage /></Suspense>} />
                            <Route path={paths.productiveUnitInfo} element={<Suspense fallback={<LoadingProgress />}><ProductiveUnitAdminPage /></Suspense>} />
                            <Route path={paths.events} element={<Suspense fallback={<LoadingProgress />}><EventListPage /></Suspense>} />
                            <Route path={paths.event} element={<Suspense fallback={<LoadingProgress />}><Event /></Suspense>} />
                            <Route path={paths.newEvent} element={<Suspense fallback={<LoadingProgress />}><EventNewPage /></Suspense>} />
                            <Route path={paths.technicalMonitoring} element={<Suspense fallback={<LoadingProgress />}><TechnicalMonitoring /></Suspense>} />
                            <Route path={paths.newTechnicalMonitoring} element={<Suspense fallback={<LoadingProgress />}><EventTechnicalMonitoringNewPage /></Suspense>} />
                            <Route path={paths.reports} element={<Suspense fallback={<LoadingProgress />}><ReportListPage /></Suspense>} />
                            <Route path={paths.report} element={<Suspense fallback={<LoadingProgress />}><ReportPage /></Suspense>} />
                        </Routes>
                    </FullLayout>
                </SharedContext>
            </LocalizationProvider>
        </Router>

    const login =
        <Router>
            <LoginLayout>
                <Routes>
                    <Route path={paths.index} element={<LoginPage />} />
                    <Route path={paths.login} element={<LoginPage />} />
                    <Route path="*" element={<Navigate to={paths.login} replace />} />
                </Routes>
            </LoginLayout>
        </Router>

    const loading =
        <LoadingProgress></LoadingProgress>

    if (logged) {
        if (userLoaded) {
            return full;
        }
        return loading;
    } else {
        return login;
    }

}

export default App;
