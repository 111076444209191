export const paths = {
  index: '/',
  login: '/auth/login',
  home: '/home',
  changePassword: '/changePassword',
  harvests: '/harvest/list/',
  productiveUnits: '/productiveUnit/list',
  productiveUnit: '/productiveUnit/:productiveUnitUid',
  newProductiveUnit: '/productiveUnit/new',
  productiveUnitInfo: '/productiveUnit/:productiveUnitUid/info',
  events: '/event/list',
  event: '/event/:eventUid',
  newEvent: '/event/new',
  technicalMonitoring: '/technicalMonitoring/:eventTechmonUid',
  newTechnicalMonitoring: '/technicalMonitoring/new',
  reports:'/report/list',
  report:'/report/:reportUid'
} as const

export type Path = typeof paths[keyof typeof paths]
